import Home from "./routes/home/home.component"
import { Route,Routes } from "react-router-dom"
import NavBar from "./routes/navbar/navbar"
import DetailPage from "./routes/detail-page/detail-page.component"
import ResultsPage from "./routes/result-page/result-page.component"
import BokepIndo from "./routes/kategori/bokep-indo.component"
import BokepJepang from "./routes/kategori/bokep-jepang.component"
import BokepBarat from "./routes/kategori/bokep-barat.component"


const App = ()=> {
  return (
    <div className="bg-[#343131] text-gray-500">
       <Routes>
       <Route path="/" element = {<NavBar />}>
       <Route index element = {<Home />} />
       <Route path="detail-page/:judul" element = {<DetailPage />} />
       <Route path="/result-page" element = {<ResultsPage />} />
       <Route path="bokep-indo/page/:page" element = {<BokepIndo/>}/>
       <Route path="bokep-jepang/page/:page" element = {<BokepJepang/>}/>
       <Route path="bokep-barat/page/:page" element = {<BokepBarat/>}/>
       </Route>
      </Routes>
    </div>
     
  )
}

export default App