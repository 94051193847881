import React from 'react';
import { Link, useLocation,useNavigate,useParams } from 'react-router-dom';
import data from '../../dataJson/data.json'
import { useState,useEffect } from 'react';
import Footbar from '../../component/footbar/footbar';

const ResultsPage = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const query = queryParams.get('query') || '';

  const { page } = useParams();
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const navigate = useNavigate();

  const filteredItems = data.filter((item) =>
    item.judul.toLowerCase().includes(query.toLowerCase())
  );

  const itemsPerPage = 6; 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  
  const currentItems = filteredItems.slice(startIndex, endIndex);

  
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  useEffect(() => {
    window.history.replaceState(null, '', `/result-page?query=${query}&page=${currentPage}`);
    // navigate(`/page/${currentPage}`);
  }, [currentPage, navigate]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4 h-sm:mb-11 h-smm:mb-28 h-md:mb-72 sm:mb-0">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {currentItems.length > 0 ? (
          currentItems.map((item) => (
            <Link key={item.id} to={`/detail-page/${item.judul.replace(/\s+/g, '-')}`}>
              <div key={item.id} className="border border-gray-300 rounded overflow-hidden shadow-lg">
              <img
               src={item.thumbnail} 
               alt={item.judul}
               className="w-full h-64 sm:h-60 object-cover" />
              <div className="p-4">
                <h3 className="text-lg text-pink-500 font-bold"> {item.kategori} - {item.judul}</h3>
                <p className="text-pink-500">{item.iframe.length} Video | {item.images.length} Foto</p>
              </div>
            </div>
            </Link>
            
          ))
        ) : (
          <div className='bg-[#343131] h-screen'>
            <p className="text-pink-500 text-center text-xl font-bold ">TIDAK ADA VIDEO "{query}" </p>
          </div>
          
        )}
      </div>
      <div className="flex justify-center mt-4 space-x-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Previous
        </button>

        <span className="px-4 py-2 text-pink-500 font-bold">
          Page {currentPage} of {totalPages}
        </span>

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Next
        </button>
      </div>
      <Footbar />
    </div>
  );
};

export default ResultsPage;
